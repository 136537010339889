import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import gsap, { Power0, Power2, Power3, Power4 } from 'gsap';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import _Header from '../_app/cuchillo/layout/Header';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export default class LogoCanvas {
    //static logoSVG;
    static SVGS = {
        L: {
            width: 164.28,
            height: 200,
            path: "M0,2.53H22.16V197.12H0ZM149.45,142.26C149.45,176,123,197.12,83,197.12H33.24V176H82.43c27.84,0,43.52-12.7,43.52-34.59,0-22.44-13.52-34.87-38.65-34.87H33.24V85.5H79.73c23,0,37.83-12.7,37.83-32.43,0-19.46-11.89-29.46-35.4-29.46H33.24V2.53H84.59c35.14,0,56.49,17.57,56.49,49.46,0,21.08-12.43,36.48-26,42.7C129.18,98.2,149.45,111.18,149.45,142.26Z"
        },
        C: {
            width: 44.31,
            height: 200,
            path: "M11.08,197.05V2.6H33.24V197.05Z"
        },
        R: {
            width: 386.76,
            height: 200,
            path: "M153.51,181.89A96.44,96.44,0,0,1,97.57,200C40,200,0,158.92,0,100,0,26.22,53,0,98.11,0,144.59,0,180,27.57,184.05,67.3l-24.59.27C157,53.51,145.94,20.81,98.11,20.81c-44,0-73.51,31.62-73.51,79.19C24.6,146,56,179.46,99.46,179.46c18.11,0,37-8.38,54.05-22.43Zm-70.27-83,103.52-.27v21.08H83.24Zm103.52,31.89V197.3H164.32V130.81Zm66.48,19.73-17,46.76h-27l18.11-46.76Zm115.33-10.81H227.27V118.65h141.3ZM243.79,107.57,284.06,2.7h27.83l40.28,104.87H327L298.11,28.92,269.2,107.57Zm124.86,43,18.11,46.76H360l-17.3-46.76Z"
        }
    }

    static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
    static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
    static itemL;
    static itemC;
    static lineL;
    static ref;
    static isFirstTime = true;
    static color = "#FF0000";

    static target = {
        x: 0,
        y: 0,
        x1: 0,
        y1: 0,
        width: 0,
        height: 0
    }

    static timeline;

    static _alpha = 0;
    static get alpha() { return this._alpha; }
    static set alpha(n) {
        this._alpha = Math.min(this.globalAlpha, n);
        /*this.logoSVG.style.setProperty('--opacity',  Math.min(this.globalAlpha,(1 - n)));*/
    }

    static _globalAlpha = 0;
    static get globalAlpha() { return this._globalAlpha; }
    static set globalAlpha(n) {
        this._globalAlpha = n;
        this.alpha = this._globalAlpha;
        //this.logoSVG.style.setProperty('--opacity',  (1 - n));
    }

    static resetWidth() {
        this._width = this.height * 3.53260869565;
    }

    static _width = 0;
    static get width() { return this._width; }
    static set width(n) {
        const w = n * Sizes.RATIO;
        this.heightScale = (w * this.height) / this._width;
        this._width = w;
    }

    static get heightScale() { return this._height; }
    static set heightScale(n) {
        const dif = n - this._height;

        this._height = n;
        /*this.logoSVG.style.setProperty('--height', n/Sizes.RATIO);*/

        this.itemL.height = n;
        this.itemC.height = n;
        this.itemR.height = n;

        this.lineL.height = n;
        this.lineL.offsetX = this.lineL.height * .5;

        this.lineR.height = n;
        this.lineR.offsetX = this.lineR.height * .5;

        const x1 = this._height * (220.68 / 200);
        const x2 = this._height * (320.76 / 200);

        this.x = this.x;
        this.x1 = this.x + x1;
        this.x2 = this.x + x2;
        this.y = this.y - dif;
        this.y1 = this.y1 - dif;
        this.y2 = this.y2 - dif;
    }

    static _height = 0;
    static get height() { return this._height; }
    static set height(n) {
        this._height = n;
        /* this.logoSVG.style.setProperty('--height', n/Sizes.RATIO); */

        this.itemL.height = n;
        this.itemC.height = n;
        this.itemR.height = n;

        this.lineL.height = n;
        this.lineL.offsetX = this.lineL.height * .5;

        this.lineR.height = n;
        this.lineR.offsetX = this.lineR.height * .5;

        this.x = this.x;
        this.x1 = this.x1;
        this.x2 = this.x2;
        this.y = this.y;
        this.y1 = this.y1;
        this.y2 = this.y2;
    }

    static get heightBottom() { return this._height; }
    static set heightBottom(n) {
        const dif = n - this._height;

        this._height = n;
        /*this.logoSVG.style.setProperty('--height', n/Sizes.RATIO); */

        this.itemL.height = n;
        this.itemC.height = n;
        this.itemR.height = n;

        this.lineL.height = n;
        this.lineL.offsetX = this.lineL.height * .5;

        this.lineR.height = n;
        this.lineR.offsetX = this.lineR.height * .5;

        this.x = this.x;
        this.x1 = this.x1;
        this.x2 = this.x2;
        this.y = this.y - dif;
        this.y1 = this.y1 - dif;
        this.y2 = this.y2 - dif;
    }

    static _x = 0;
    static get x() { return this._x; }
    static set x(n) {
        this._x = n;
        this.itemL.x = n;
        this.lineL.x = n + this.itemL.width;
    }

    static _x1 = 0;
    static get x1() { return this._x1; }
    static set x1(n) {
        this._x1 = n;
        this.itemC.x = n;// - this.itemL.width;
        this.lineL.x1 = this.itemC.x;
        this.lineR.x = this.itemC.x + this.itemC.width;
    }

    static _x2 = 0;
    static get x2() { return this._x2; }
    static set x2(n) {
        this._x2 = n;
        this.itemR.x = n;
        this.lineR.x1 = this.itemR.x;
    }

    static _y = 0;
    static get y() { return this._y; }
    static set y(n) {
        this._y = n;
        this.itemL.y = n;
        this.lineL.y = n;
    }

    static _y1 = 0;
    static get y1() { return this._y1; }
    static set y1(n) {
        this._y1 = n;
        this.itemC.y = n;
        this.lineL.y1 = n;
        this.lineR.y = n;
    }

    static _y2 = 0;
    static get y2() { return this._y2; }
    static set y2(n) {
        this._y2 = n;
        this.itemR.y = n;
        this.lineR.y1 = n;
    }

    static get yBottom() { return this._y; }
    static set yBottom(n) {
        this._y = n + this.itemL.height;
        this.itemL.y = this._y;
        this.lineL.y = this._y;
    }

    static get yBottom1() { return this._y1; }
    static set yBottom1(n) {
        this._y1 = n + this.itemC.height;
        this.itemC.y = this._y;
        this.lineL.y1 = this._y;
        this.lineR.y = this._y;
    }

    static get yBottom2() { return this._y2; }
    static set yBottom2(n) {
        this._y2 = n + this.itemR.height;
        this.itemR.y = this._y;
        this.lineR.y1 = this._y;
    }

    static cb = () => { };

    static init() {
        if (isSmartphone) return;

        //this.logoSVG = GetBy.id("InterfaceLogo");

        this.itemL = new Logo_Item(this.ctx, this.SVGS.L, "black");
        this.itemC = new Logo_Item(this.ctx, this.SVGS.C, "black");
        this.itemR = new Logo_Item(this.ctx, this.SVGS.R, "black");

        this.lineL = new Logo_Line(this.ctx, "black");
        this.lineR = new Logo_Line(this.ctx, "black");

        EventDispatcher.addEventListener(_Header.ON_SHOW, () => this.show());
        EventDispatcher.addEventListener(_Header.ON_HIDE, () => this.hide());
    }

    static show() {
        gsap.to(this, { alpha: 1, duration: .2, ease: Power2.easeOut });
    }

    static hide() {
        gsap.to(this, { alpha: 0, duration: .2, ease: Power2.easeOut });
    }

    static start(cb = () => { }, __ref) {
        if (isSmartphone) {
            cb();
            return;
        }

        this.show();
        this.ref = __ref || GetBy.id("LogoBiga");

        if (!this.ref) {
            gsap.to(this, { globalAlpha: 0, duration: .2, ease: Power2.easeOut });
            this.cb = cb;
            this.cb();
        } else {
            this.globalAlpha = 1;
            this.cb = cb;
            this._calculateRect();
        }
    }

    static changeColor(__color) {
        if (isSmartphone) return;
        gsap.to(this, { color: __color, duration: .2, delay: .3, ease: Power2.easeOut });
    }

    static goBlack() {
        this.changeColor("#000");
    }

    static goPink() {
        this.changeColor("#FF3263");
    }

    static goWhite() {
        this.changeColor("#FFFF");
    }

    static goGrey() {
        this.changeColor("#E2E6EE");
    }

    static _calculateRect(__isResizeDirect = false) {
        if(!this.ref) return;
        
        const rect = this.ref.getBoundingClientRect();
        const ratio = Sizes.RATIO;

        const h = rect.height * ratio;

        const x0 = h * 0;
        const x1 = h * (220.68 / 200);
        const x2 = h * (320.76 / 200);

        this.target = {
            x: rect.x * ratio + x0,
            y: rect.y * ratio,
            x1: rect.x * ratio + x1,
            y1: rect.y * ratio,
            x2: rect.x * ratio + x2,
            y2: rect.y * ratio,
            width: rect.width * ratio,
            height: rect.height * ratio,
        };

        /*this.logoSVG.style.setProperty('--x', `${rect.x}px`);
        this.logoSVG.style.setProperty('--y', `${rect.y}px`);*/

        if (__isResizeDirect || this.isFirstTime || (this.x == this.target.x && this.y == this.target.y)) {
            this.isFirstTime = false;
            this._width = this.target.width,
            this.height = this.target.height,
            this.x = this.target.x;
            this.y = this.target.y;
            this.x1 = this.target.x1;
            this.y1 = this.target.y;
            this.x2 = this.target.x2;
            this.y2 = this.target.y;
            if (this.cb) this.cb();
        } else {
            this.setupTimeline();
            this.timeline.restart();
        }
    }

    static setupTimeline() {
        if (this.timeline) {
            this.timeline.kill();
            this.timeline = null;
        }

        this.timeline = gsap.timeline({ paused: true });

        const isTargetLeft = this.target.x < this.x;
        const axisX = {
            first: isTargetLeft ? "x" : "x2",
            last: isTargetLeft ? "x2" : "x",
        };

        const modY = /*this.target.y > this.y &&*/ this.target.height > this.height ? this.height : 0;
        const animationCenter = Math.random() > 0.5;
        /*const axisY = {
            first: animationCenter ? "y" : "y2",
            last: animationCenter ? "y2" : "y",
        };*/
        const axisY = {
            first: isTargetLeft ? "y" : "y2",
            last: isTargetLeft ? "y2" : "y",
        };

        const animationCenterY = Math.random();

        let centerSide;
        if (animationCenterY > .5) {
            centerSide = axisX.first;
        } else {
            centerSide = axisX.last;
        }

        let timeYCenter = 0;
        if ((centerSide === "x" && axisY.first === "y") || (centerSide === "x2" && axisY.first === "y2")) {
            timeYCenter = .2;
        } else {
            timeYCenter = .4;
        }

        const durationShort = 0.3;
        const durationLong = 0.4;
        const durationExtraLong = 0.8;

        if (this.target.height < this.height) {
            this.timeline.to(this, {
                heightBottom: this.target.height,
                ease: Power3.easeInOut,
                duration: durationShort,
            }, 0);
        }

        this.timeline.to(this, {
            [axisX.first]: this.target[axisX.first],
            ease: Power3.easeInOut,
            duration: durationShort,
        }, 0);

        if (animationCenterY > 0.5) {
            this.timeline.to(this, {
                x1: this.target.x1,
                ease: Power3.easeInOut,
                duration: durationShort,
            }, 0);
        }

        this.timeline.to(this, {
            [axisY.first]: this.target[axisY.first] + modY,
            ease: Power3.easeOut,
            duration: durationLong,
        }, 0.2);

        this.timeline.to(this, {
            y1: this.target.y1 + modY,
            ease: Power3.easeOut,
            duration: durationLong,
        }, timeYCenter);

        this.timeline.to(this, {
            [axisY.last]: this.target[axisY.last] + modY,
            ease: Power3.easeOut,
            duration: durationLong,
        }, 0.4);

        this.timeline.to(this, {
            [axisX.last]: this.target[axisX.last],
            ease: Power4.easeOut,
            duration: durationLong,
            onComplete: () => {
                if (this.cb && this.target.height <= this.height) this.cb();
            }
        }, 0.8);

        if (animationCenterY <= 0.5) {
            this.timeline.to(this, {
                x1: this.target.x1,
                ease: Power4.easeOut,
                duration: durationLong,
            }, 0.8);
        }

        if (this.target.height > this.height) {
            this.timeline.to(this, {
                heightBottom: this.target.height,
                ease: Power4.easeOut,
                duration: durationExtraLong,
                onComplete: () => {
                    if (this.cb) this.cb();
                }
            }, 1);
        }
    }

    static stop() {
        this.cb = null;
    }

    static loop() {
        if (isSmartphone) return;

        this.clear();
        this.draw();
    }

    static clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    static draw() {
        this.ctx.globalAlpha = this.alpha;
        this.itemL.draw(this.color);
        this.itemC.draw(this.color);
        this.itemR.draw(this.color);
        this.lineL.draw(this.color);
        this.lineR.draw(this.color);
        this.ctx.globalAlpha = 1;
    }

    static resize() {
        if (isSmartphone) return;
        
        this._calculateRect(true);
    }

    static dispose() {

    }
}

class Logo_Item {
    x = 0;
    y = 0;
    width = 0;
    scale
    _height = 0;
    ctx;
    color;
    svg;
    _path2D

    get height() { return this._height; }
    set height(n) {
        this._height = n;
        this.scale = this._height / this.svg.height;
        this.width = this.svg.width * this.scale;
    }

    constructor(__ctx, __svg, __color) {
        this.ctx = __ctx;
        this.color = __color;
        this.svg = __svg;
        this.path2D = new Path2D(this.svg.path);
    }

    draw(__color) {
        const { x, y, scale, path2D } = this;

        this.ctx.save();
        this.ctx.translate(x, y);
        this.ctx.scale(scale, scale);
        this.ctx.fillStyle = __color;
        this.ctx.fill(path2D);
        this.ctx.restore();
    }
}

class Logo_Line {
    x = 0;
    y = 0;
    x1 = 0;
    y1 = 0;

    ctx;
    color;

    lineHeight = 20.8099;
    lineWidth = 56.0861;
    lineOffset = this.lineHeight / 2;
    lineY = 2.5956;
    sizes = {
        lineHeight: 20.8099,
        lineWidth: 56.0861,
        lineOffset: 20.8099 * .5,
        lineY: 2.5956
    }

    _height;
    get height() { return this._height; }
    set height(n) {
        this._height = n;
        this.scale = this._height / 200;
        this.lineHeight = this.sizes.lineHeight * this.scale;
        this.lineWidth = this.sizes.lineWidth * this.scale;
        this.lineOffset = this.sizes.lineOffset * this.scale;
        this.lineY = this.sizes.lineY * this.scale;
    }

    get width() { return this.lineWidth; }

    _offsetX;
    get offsetX() { return this._offsetX; }
    set offsetX(n) {
        this._offsetX = n - this.lineOffset * 2;
    }

    constructor(__ctx, __color) {
        this.ctx = __ctx;
        this.color = __color;
    }

    draw(__color) {
        let { x, y, x1, y1, ctx, lineOffset, lineHeight, lineWidth, height, offsetX } = this;
        const mod = y < y1 ? 1 : y > y1 ? -1 : 0;

        if (Math.abs(x - x1) < lineWidth * 1.5) {
            y1 = y;
            offsetX = 0;
        }

        this.drawline(x, y + this.lineY, x1, y1 + this.lineY, ctx, __color, lineOffset, lineHeight, lineWidth, offsetX * mod);
        this.drawline(x, y + height - this.lineY, x1, y1 + height - this.lineY, ctx, __color, -lineOffset, lineHeight, lineWidth, -offsetX * mod);
    }

    drawline(x, y, x1, y1, ctx, color, lineOffset, lineHeight, lineWidth, offsetX) {
        const midX = ((x + x1) * .5) + offsetX;

        ctx.beginPath();
        ctx.moveTo(x, y + lineOffset);
        ctx.lineTo(midX, y + lineOffset);
        ctx.lineTo(midX, y1 + lineOffset);
        ctx.lineTo(x1, y1 + lineOffset);
        ctx.lineWidth = lineHeight; // Establecer el ancho de la línea en 20 píxeles
        ctx.strokeStyle = color;
        ctx.stroke();
    }

    resize() {

    }
}