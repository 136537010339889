import { GetBy } from '../core/Element';
import VScroll from '../scroll/VScroll';
import VScroll_Item from '../scroll/VScroll_Item';
import Scrollbar from '../scroll/Scrollbar';
import { MrInteraction } from '../core/Interaction';
import { gsap } from 'gsap';
import { Basics } from '../core/Basics';
import { Maths } from '../utils/Maths';

export class SliderScroll {
  _container;
  _holder;
  _scroll;
  _scrollBar;
  _interaction;
  _step;
  _call;
  _controls = {}
  _current = 0;
  _gap = 0;
  opts;

  get size() {
    return this._container.offsetWidth + this._scroll.size;
  }

  get sizeOffScreen() {
    return this._scroll.size - this._container.offsetWidth;
  }

  get progress() {
    return this._scroll.progress;
  }

  set progress(progress) {
    this._scroll.goto_percetage(progress, true);
  }

  get total() {
    return this._scroll.total_items;
  }

  get x() {
    return this._scroll.position;
  }

  get actual() {
    if (this._scroll._items) {
      return Math.abs(Math.round(this._scroll.position / (this._scroll._items[this._current].width + this._gap)));
    } else {
      return Math.round(this.total * this.progress);
    }
  }

  get items() {
    return this._scroll._items;
  }

  constructor(__container, options = {}) {
    this._container = __container;
    this._holder = GetBy.class('__holder', __container)[0];
    this.opts = options;

    this._scroll = new VScroll({
      container: this._holder,
      axis: 'X',
      wheel: false,
      itemClass: options.itemClass || SliderScroll__Item,
      easing: options.easing,
      smooth: options.smooth,
      hasLimits: options.hasLimits,
      endOutside: options.endOutside,
      hasSnap: options.hasSnap
    });

    if (options.hasScrollbar) {
      this._scrollBar = new Scrollbar(options.domScrollbar || GetBy.class('scrollbar', options.domControls || __container)[0]);
      this._scroll.setScrollbar(this._scrollBar);
      this._scrollBar.update(0);
    }
    this._scroll.addAll(options.selector || '[scroll-slider-item]');
    this._scroll.resize();
    this._scroll.loop(true);
    this._scroll.start();

    this._step = (1 / (this.total - 1));

    if (!options.interaction === false) {
      // if (options.hasSnap) {
      //   this._hammer = new Hammer(this._container, {});
      //   this._hammer.on('swipeleft', () => this.next());
      //   this._hammer.on('swiperight', () => this.prev());
      // } else {
      this._interaction = new MrInteraction(this._holder, {
        drag: true,
        axis: 'x',
        dragCheckTime: .05,
        onMove: (n) => {
          if (options.onMove) options.onMove();
          this._scroll.move(n)
        },
        onDragStart: () => {
          if (options.onDragStart) options.onDragStart();
        },
        onDragEnd: () => {
          if (options.onDragEnd) options.onDragEnd();
        }
      });
      // }
    }

    /* CONTROLS */

    this._controls = {
      next: GetBy.selector('[scroll-slider-next]', options.domControls || __container)[0],
      prev: GetBy.selector('[scroll-slider-prev]', options.domControls || __container)[0],
      current: GetBy.selector('[scroll-slider-current]', options.domControls || __container)[0],
      total: GetBy.selector('[scroll-slider-total]', options.domControls || __container)[0],
      progress: GetBy.selector('[scroll-slider-progress]', options.domControls || __container)[0]
    }

    if (this._controls.next) {
      this._controls.next.addEventListener(Basics.clickEvent, (e) => {
        this.next();
      });
    }

    if (this._controls.prev) {
      this._controls.prev.addEventListener(Basics.clickEvent, (e) => {
        this.prev();
      });
    }

    if (this._controls.total) {
      this._controls.total.innerHTML = this.total;
    }

    if (this._controls.progress) {
      gsap.to(this._controls.progress, { drawSVG: `${0}% ${Math.ceil(this.progress * 100)}%` });
    }

    this._call = () => this.loop();
  }

  start() {
    gsap.ticker.add(this._call);
  }

  stop() {
    gsap.ticker.remove(this._call);
  }

  step(__step) {
    this._scroll.gotoStep(__step)
  }

  directGoto(__n) {
    this._scroll.gotoStep(__n)
  }

  gotoIndex(__n) {
    this._scroll.goto_percetage(__n / (this.total - 1), true);
  }

  next() {
    this._current = Math.min(this.total - 1, this.actual + 1);
    this._scroll.goto(this._scroll._items[this._current].left);
  }

  prev() {
    this._current = Math.max(0, this.actual - 1);
    this._scroll.goto(this._scroll._items[this._current].left);
  }

  goto_percetage(__p) {
    this._scroll.goto_percetage(__p, true);
  }

  loop() {
    /*if(Math.abs(this._scroll.speed) < 0.001 && Math.abs(this._scroll.speed) != 0) {
      this.next();
    }*/

    if (this._controls.current) {
      const i = Math.max(Math.ceil(Maths.lerp(0, this.total, this.progress)), 1);
      this._container.style.setProperty('--index', i - 1);
      this._controls.current.innerHTML = i;
    }

    if (this._controls.progress) {
      gsap.to(this._controls.progress, { drawSVG: `${0}% ${this.progress * 100}%` });
    }

    this._scroll.loop();
  }

  resize() {
    this._scroll.resize();

    if (this._scroll._items[1]) {
      this._gap = this._scroll._items[1].left - this._scroll._items[0].width;
    } else {
      this._gap = 0;
    }
  }

  dispose() {
    this._scroll.dispose();
    if (this._interaction) {
      this._interaction.dispose();
    }

    if (this._scrollBar) {
      this._scrollBar.dispose();
    }
  }
}

class SliderScroll__Item extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  mouseOver() { }
  mouseDown() { }
  mouseUp() { }

  show() {
    super.show();
  }

  hide() {
    super.hide();
  }
}
