import { Power2, gsap } from "gsap";

// import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from "../_app/cuchillo/core/Element";
import { Basics, isMobile, isSmartphone } from "../_app/cuchillo/core/Basics";
import { Functions } from "../_app/cuchillo/utils/Functions";

class ScrollItem__WidgetContact extends VScroll_Item {
    maps;
    logos;
    timeouts = [];

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._click = (e) => this.click(e);
        this._mouseEnter = (e) => this.mouseEnter(e);
        this._mouseLeave = (e) => this.mouseLeave(e);
        this.maps = GetBy.class('__map', __link);
        this.logos = [...GetBy.class('__logo', __link)];

        this.onShow = () => {
            this.showCells();
            this.addEvents();
        };
        this.onHide = () => {
            this.removeEvents();
        };
        this.onMove = () => { };
    }

    showCells() {
        let delay = 1;
        const actives = this.logos.filter(logo => logo.classList.contains('--active'))
        Functions.arrayRandom(actives).map((target, i) => {
            gsap.to(target, {
                backgroundColor: '#E2E6EE',
                duration: .05,
                delay: delay + .02 * i,
                onComplete: () => {
                    this.maps[target.dataset.index].classList.remove('--active');
                }
            });

            gsap.to(target, {
                backgroundColor: 'transparent',
                delay: delay + .02 * i + .15,
                duration: .3,
                ease: Power2.easeOut,
                onComplete: () => {
                    target.classList.remove('--active');

                }
            });
        });
    }

    click(e) {
        const target = e.target;
        target.removeEventListener(Basics.clickEvent, this._click);

        if (!isSmartphone) {
            target.removeEventListener(Basics.mouseOver, this._mouseEnter);
            target.removeEventListener(Basics.mouseOut, this._mouseLeave);
        }

        target.classList.add('--line');

        gsap.killTweensOf(target);
        this.showTarget(target, () => {
            target.classList.add('--active');
            this.maps[target.dataset.index].classList.add('--active');
        }, () => {
            target.classList.remove('--line');
        });
    }

    showTarget(target, cb = () => { }, cb2 = () => { }) {
        gsap.to(target, {
            backgroundColor: '#E2E6EE',
            duration: .05,
            onComplete: () => {
                cb();
            }
        });
        gsap.to(target, {
            backgroundColor: 'transparent',
            delay: .15,
            duration: .3,
            ease: Power2.easeOut,
            onComplete: () => {
                cb2();
            }
        });
    }

    mouseEnter(e) {
        const target = e.target;
        const index = target.dataset.index;

        clearTimeout(this.timeouts[index]);

        this.showTarget(target, () => {
            target.classList.add('--hover');
            this.maps[index].classList.add('--hover');
        });
    }

    mouseLeave(e) {
        const target = e.target;
        const index = target.dataset.index;

        this.timeouts[index] = setTimeout(() => {
            target.classList.remove('--hover');
            this.maps[target.dataset.index].classList.remove('--hover');
        }, 2000);
    }

    addEvents() {
        for (let i = 0; i < this.logos.length; i++) {
            const logo = this.logos[i];
            logo.addEventListener(Basics.clickEvent, this._click);

            if (!isSmartphone) {
                logo.addEventListener(Basics.mouseOver, this._mouseEnter);
                logo.addEventListener(Basics.mouseOut, this._mouseLeave);
            }
        }
    }

    removeEvents() {
        for (let i = 0; i < this.logos.length; i++) {
            const logo = this.logos[i];
            logo.removeEventListener(Basics.clickEvent, this._click);

            if (!isSmartphone) {
                logo.removeEventListener(Basics.mouseOver, this._mouseEnter);
                logo.removeEventListener(Basics.mouseOut, this._mouseLeave);
            }
        }
    }
}

Scroll._registerClass('widget-contact', ScrollItem__WidgetContact);
