import gsap from 'gsap';

import { isMobile } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Default from './Default';
import { C, GetBy } from '../_app/cuchillo/core/Element';

export default class Project extends Default {
  isBannerShow = true;
  banner;
  linksContainer;
  category;

  _calls = {
    check: () => this.checkBanner()
  }

  constructor() {
    super();

    if (!isMobile) this.banner = GetBy.id('Banner');

    this.linksContainer = GetBy.class('__links', this.container)[0];

    const press = GetBy.id('Press');
    if (press) this.linksContainer.classList.add('--press');

    const blueprints = GetBy.id('Blueprints');
    if (blueprints) this.linksContainer.classList.add('--blueprints');

    this.category = this.container.dataset.category;
  }

  afterShow() {
    super.afterShow();

    if (!isMobile) gsap.ticker.add(this._calls.check);
  }

  beforeHide() {
    super.beforeHide()
    if (!isMobile) gsap.ticker.remove(this._calls.check);
  }

  checkBanner() {
    if (Scroll.direction === -1 && !this.isBannerShow) {
      this.showBanner();
    } else if (Scroll.direction === 1) {
      this.hideBanner();
    }
  }

  showBanner() {
    if (!this.isBannerShow) {
      this.isBannerShow = true;
      this.banner.style.opacity = 1;
      this.banner.style.pointerEvents = 'initial';
    }
  }

  hideBanner() {
    if (this.isBannerShow) {
      this.isBannerShow = false;
      this.banner.style.opacity = 0;
      this.banner.style.pointerEvents = 'none';
    }
  }
}

ControllerPage._addPage('project', Project);
