import { Power3 } from 'gsap';

import { C, GetBy } from '../_app/cuchillo/core/Element';
import _Header from '../_app/cuchillo/layout/Header';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class Header extends _Header {
  static langBtn = GetBy.id('LangBtn');
  static langs = GetBy.id('Langs');

  static options = {
    show: {
      duration: 0,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: 0,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static _calls = {
    openLang: (event) => {
      event.stopPropagation();
      this.langBtn.style.display = 'none';
      this.langs.style.display = 'block';

      document.addEventListener(Basics.clickEvent, this._calls.closeLang);

    },
    closeLang: (event) => {
      if (!event.target.closest('#Langs')) {
        this.langBtn.style.display = 'initial';
        this.langs.style.display = 'none';

        document.removeEventListener(Basics.clickEvent, this._calls.closeLang);
      }
    }
  }

  static init() {
    super.init();

    this.links = [...GetBy.selector('a:not([data-hreflang])', this.container)];
    this.langBtn.addEventListener(Basics.clickEvent, this._calls.openLang);
  }

  static setCurrentPage() {
    this.links
      .filter(item => item.getAttribute('aria-current') === 'page')
      .map(item => {
        item.removeAttribute('aria-current');
      });

    this.links
      .filter(item => item.classList.contains('--current-page'))
      .map(item => {
        item.classList.remove('--current-page');
      });

    this.links
      .filter(link => link.href === window.location.href)
      .map(link => {
        link.setAttribute('aria-current', 'page');
      });
  }

  static setCurrentPageCategory(categ) {
    console.log('Select category', window.location.href);

    this.links
      .filter(item => item.getAttribute('aria-current') === 'page')
      .map(item => {
        item.removeAttribute('aria-current');
      });
    this.links
      .filter(item => item.classList.contains('--current-page'))
      .map(item => {
        item.classList.remove('--current-page');
      });

    const categs = this.links.filter(link => link.href === window.location.href);
    if (categs.length > 0) {
      const link = categs[0];
      link.setAttribute('aria-current', 'page');
      const parent = link.parentElement.previousElementSibling;
      if (parent) parent.setAttribute('aria-current', 'page');
    } else {
      const currentLink = GetBy.selector('[data-category-id="' + categ + '"]', this.container)[0];
      if (currentLink) {
        currentLink.classList.add('--current-page');
        const parent = currentLink.parentElement.previousElementSibling;
        if (parent) parent.setAttribute('aria-current', 'page');
      }
    }
  }
}
