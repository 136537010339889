import gsap from 'gsap';

import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Default from './Default';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import LogoCanvas from '../components/LogoCanvas';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Free extends Default {
  isBannerShow = true;
  banner;
  linksContainer;
  category;

  _calls = {}

  constructor() {
    super();

    C.remove(GetBy.id("Header"));

    setTimeout(()=> {
      LogoCanvas.changeColor("#000000")
    },2000)
    

    const keys = [49,50,51,81,87,69,65,83,68,90,88,67,52,53,54];
    const keysBi = [[49,50,51],[81,87,69],[65,83,68],[90,88,67],[52,53,54]]
    const index = [0,1,2,3,4];

    let i=0;

    Keyboard.enable();

    [...GetBy.selector("[data-button-logo]")].map(dom => {
      Keyboard.add(keys[i],keys[i],()=> {
        LogoCanvas.start(null, dom)
      })

      i++;
        dom.addEventListener(Basics.clickEvent, e=> {
          console.log(dom)
          LogoCanvas.start(null, dom)
          

          
        })
    });

    let oldN2 = 0;
    setInterval(()=> {
      const list = Functions.arrayRandom(index);
      const n1 = Maths.maxminRandom(4, 0);
      let n2 = Maths.maxminRandom(2, 0);
      do {
        n2 = Maths.maxminRandom(2, 0);
      } while (n2 === oldN2);

      oldN2 = n2;

      Keyboard._checkManual(keysBi[n1][n2]);
    }, 2000)
  }
}

ControllerPage._addPage('free', Free);
