import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from "../_app/cuchillo/core/Element";
import { Basics, isMobile, isSmartphone } from "../_app/cuchillo/core/Basics";

class ScrollItem__BlockProjectBillboard extends VScroll_Item {
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (isMobile) {
            const categArticle = GetBy.selector('article[data-category]')[0];

            if (categArticle) {
                const categId = categArticle.dataset.category;
                const categs = GetBy.selector('[data-category-id]');

                for (let i = 0; i < categs.length; i++) {
                    const categ = categs[i];
                    if (categ.dataset.categoryId === categId) categ.setAttribute('aria-current', 'page');
                }
            }
        }

        this.onShow = () => { };
        this.onHide = () => { };
        this.onMove = () => { };
    }
}

Scroll._registerClass('block-project-billboard', ScrollItem__BlockProjectBillboard);
