import { gsap } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

class ScrollItem__Footer extends VScroll_Item {
    _logoWidth = 0;
    _logo;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (!isMobile) this._logo = GetBy.class('__logo-footer', this.container);

        this.onShow = () => { };
        this.onHide = () => { };
        this.onMove = () => {
            this.loop();
        };
    }

    resize(w, h) {
        super.resize(w, h);

        if (this._logo) {
            this._logo[0].style = '';
            this._logoWidth = this._logo[0].offsetWidth;
        }
    }

    loop() {
        if (isMobile) return;

        const p = 1 + this.progressInside;
        const width = Maths.lerp(0, this._logoWidth, p);
        gsap.set(this._logo, { width });
    }

    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('footer', ScrollItem__Footer);
