import Page from './Page';
import { GetBy } from '../core/Element';
import LoaderController from '../loaders/LoaderController';
import { Analytics } from '../core/Analytics';
import { ControllerWindow } from '../windows/ControllerWindow';
import { Scroll } from '../scroll/Scroll';
import { KEYS } from '../core/Keyboard';

export const ControllerPage = {
  host: new URL(window.location).host,
  container: null,
  loader: null,
  page: null,
  pageOut: null,
  state: 0, //ALL OK 1: RUNNING 2:WAITING FOR NEXT
  firsTime: true,
  userAction: false,
  _directHref: "",
  _selector: "",
  _historyType: false,
  _waitingData: null,
  _preloadHref: false,
  _cont: 0,
  dataStates: [],
  pageClasses: {},


  init: function (__container) {
    this.container = __container;
    this._loader = LoaderController._loaders.PagesLoader;

    window.onpopstate = () => { this.popState(); };

    setTimeout(() => {
      this.pushState({ scrollX: window.pageXOffset, scrollY: window.pageYOffset }, null, window.location.href);
      this._continueLoad();
    }, 100);
  },

  _addPage: function (__id, __class) {
    this.pageClasses[__id] = __class;
  },

  enable_ESC_Mode(__isON = true) {
    if (__isON) {
      Keyboard.remove(KEYS.ESC, "Page_ESC");
      Keyboard.add(KEYS.ESC, "Page_ESC", () => { this.back(); });
    } else {
      Keyboard.remove(KEYS.ESC, "Page_ESC");
    }
  },

  back(__safeURL = null) {
    if (ControllerPage.dataStates.length > 1) {
      history.back();
    } else if (__safeURL) {
      this.changePage(__safeURL);
    } else {
      this.changePage(GetBy.id("BackLINK").value);
    }
  },

  popState() {
    this._cont--;
    this.dataStates.pop();
    this._hidePage();
  },

  pushState(__data, __title, __url) {
    this._cont++;
    this.dataStates.push({ data: __data, title: __title, url: __url });
    history.pushState(__data, __title, __url);
  },

  replaceState(__data, __title, __url) {
    this.dataStates[this.dataStates.length - 1] = { data: __data, title: __title, url: __url };
    history.replaceState(__data, __title, __url);
  },

  changePage: function (__href = "", __historyType = "push", __selector = "main", __section = null) {
    if (__href === ControllerPage._directHref) {
      this.state = 0;
    } else {
      if (this.state === 0) {
        this.state = 1;
        this.userAction = true;
        this._directHref = __href;
        this._historyType = __historyType;
        this._selector = __selector;

        if (this._historyType === "push") {
          history.replaceState({ scrollX: -Scroll.x, scrollY: -Scroll.y }, null, window.location.href);
          this.dataStates[this.dataStates.length - 1].data = { scrollX: -Scroll.x, scrollY: -Scroll.y }

          ControllerPage.pushState({ scrollX: 0, scrollY: 0, section: __section }, null, this._directHref);
        } else {
          ControllerPage.replaceState({ scrollX: 0, scrollY: 0, section: __section }, null, this._directHref);
        }

        this._hidePage();

      } else {
        this.state = 2;
        this._waitingData = { _directHref: __href, _historyType: __historyType, _selector: __selector, _section: __section }
      }
    }
  },

  disposeOut: function () {
    if (this.pageOut != null) {
      this.pageOut._dispose();
      this.pageOut = null;

      if (this.state < 2) {
        this.state = 0;
      } else {
        this.state = 0;
        this.changePage(
          this._waitingData._directHref,
          this._waitingData._historyType,
          this._waitingData._selector,
          this._waitingData._section
        );
      }
    }
  },

  _hidePage: function () {
    if (this.firsTime) this._loadPage();
    else {
      if (this.page) {
        this.page._hide();
      }
    }
  },

  preloadPage: function (__href) {
    if (!ControllerPage._loader.getData(__href) && ControllerPage._preloadHref !== __href) {
      ControllerPage._preloadHref = __href;
      ControllerPage._loader.loadPage(ControllerPage._preloadHref, function () {
        ControllerPage._preloadHref = null
      });
    }
  },

  _loadPage: function () {
    this.pageOut = this.page;
    this.page = null;

    if (this.firsTime) {
      this.continueLoad();
    } else {
      this._directHref = window.location.href; //Utils.UrlManager.url;
      let _p = ControllerPage._loader.getData(ControllerPage._directHref);

      if (_p != null) {
        const dataPage = ControllerPage._parsePage(_p.page);
        ControllerPage._renderPage(dataPage);
        ControllerPage._continueLoad();

        Analytics.sendUrl(ControllerPage._directHref, dataPage.title);
      } else {
        const pageLoaded = (__data) => {
          ControllerPage._preloadHref = null;

          const dataPage = ControllerPage._parsePage(__data.page);
          ControllerPage._renderPage(dataPage);
          ControllerPage._continueLoad();

          Analytics.sendUrl(ControllerPage._directHref, dataPage.title);
        };

        if (ControllerPage._preloadHref === ControllerPage._directHref) {
          ControllerPage.onFileLoaded = pageLoaded;
        } else {
          ControllerPage._loader.loadPage(ControllerPage._directHref, pageLoaded)
        }
      }
    }
  },

  _parsePage: function (__page) {
    var data = __page;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(data, "text/html");
    let alternates = [];

    [...GetBy.selector('[rel="alternate"]', xmlDoc.documentElement)].map(item => {
      alternates.push({
        hreflang: item.getAttribute("hreflang"),
        href: item.getAttribute("href"),
      });
    });

    return {
      canonical: GetBy.selector('[rel="canonical"]', xmlDoc.documentElement)[0]?.getAttribute("href") || null,
      alternates: alternates,
      og: {
        title: GetBy.selector('[property="og:title"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        type: GetBy.selector('[property="og:type"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        url: GetBy.selector('[property="og:url"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        image: GetBy.selector('[property="og:image"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        site_name: GetBy.selector('[property="og:site_name"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        description: GetBy.selector('[property="og:description"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
      },
      twitter: {
        title: GetBy.selector('[property="twitter:title"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        card: GetBy.selector('[property="twitter:card"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        description: GetBy.selector('[property="twitter:description"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
        image: GetBy.selector('[property="twitter:image"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
      },
      title: GetBy.selector("title", xmlDoc.documentElement)[0]?.innerText || null,
      description: GetBy.selector('[name="description"]', xmlDoc.documentElement)[0]?.getAttribute("content") || null,
      page: xmlDoc.documentElement.getElementsByClassName("wrap")[0] || null,
    };
  },

  _renderPage: function (__data) {
    ControllerPage.container.insertBefore(__data.page, ControllerPage.container.firstChild);

    /* METAS */
    document.title = __data.title || '';
    setMetaContent('meta[name="description"]', __data.description);
    setAttributeIfExist('link[rel="canonical"]', "href", __data.canonical);

    /* OG */
    setMetaContent('meta[property="og:title"]', __data.og?.title);
    setMetaContent('meta[property="og:type"]', __data.og?.type);
    setMetaContent('meta[property="og:url"]', __data.og?.url);
    setMetaContent('meta[property="og:image"]', __data.og?.image);
    setMetaContent('meta[property="og:site_name"]', __data.og?.site_name);
    setMetaContent('meta[property="og:description"]', __data.og?.description);

    /* TWITTER */
    setMetaContent('meta[property="twitter:title"]', __data.twitter?.title);
    setMetaContent('meta[property="twitter:card"]', __data.twitter?.card);
    setMetaContent('meta[property="twitter:description"]', __data.twitter?.description);
    setMetaContent('meta[property="twitter:image"]', __data.twitter?.image);

    /* LANGS */
    __data.alternates?.forEach(item => {
      setAttributeIfExist(`link[hreflang="${item.hreflang}"]`, 'href', item.href);
      setAttributeIfExist(`[data-hreflang="${item.hreflang}"]`, 'href', item.href);
    });

    function setMetaContent(selector, content) {
      const element = document.querySelector(selector);
      if (element) {
        element.setAttribute("content", content || '');
      }
    }

    function setAttributeIfExist(selector, attribute, value) {
      const element = document.querySelector(selector);
      if (element) {
        element.setAttribute(attribute, value || '');
      }
    }
  },

  _continueLoad: function () {
    this.page = ControllerPage.getTypePage();
    this.page._load(ControllerPage.firsTime);
    this.firsTime = false;
  },

  //PAGES
  getTypePage: function () {
    const pageId = GetBy.selector('[data-page]')[0].getAttribute("data-page");
    const page = this.pageClasses[pageId] || Page;

    if (!this.pageClasses[pageId]) {
      console.warn(`data-page [${pageId}] no existe, posiblemente no hayas hecho el import`);
    }

    return new page();
  },

  loop: function () {
    if (ControllerPage.pageOut) ControllerPage.pageOut.loop();
    if (ControllerPage.page) ControllerPage.page.loop();
  },

  resize: function () {
    if (ControllerPage.page) ControllerPage.page.resize();
  },

  isUrlSameHost: function (__hrefURL) {
    return (__hrefURL.startsWith("/") || new URL(__hrefURL).host === this.host);
  }
};
