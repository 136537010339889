import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class ProjectCategory extends Default {
  constructor() {
    super();

    this.category = this.container.dataset.category;
  }
}

ControllerPage._addPage('ProjectCategory', ProjectCategory);
