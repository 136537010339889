import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Functions } from '../_app/cuchillo/utils/Functions';

class ScrollItem__AboutRow extends VScroll_Item {
  items;
  shown = false;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.items = Functions.arrayRandom([...GetBy.class('__benefit', __link)]);

    this.onShow = () => {
      this.showItems();
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  showItems() {
    if (this.shown) return;

    this.shown = true;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      gsap.delayedCall(.2 + .15 * i, () => {
        item.classList.add('--loaded');
        item.classList.add('--line');
      });
      gsap.delayedCall(.35 + .15 * i, () => {
        item.classList.add('--animated');
      });
      gsap.delayedCall(.65 + .15 * i, () => {
        item.classList.remove('--line');
      });
    }
  }
}

Scroll._registerClass('about-row', ScrollItem__AboutRow);
