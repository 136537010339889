import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import Forms from '../_app/cuchillo/forms/FormValidator';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Cmp from '../windows/Cmp';
import { GetBy } from '../_app/cuchillo/core/Element';
import Header from '../layout/Header';
import LogoCanvas from '../components/LogoCanvas';

export default class Default extends Page {

  logo_color;

  constructor() {
    super();

    this.logo_color = this.container.getAttribute("data-logo-color")
    
    Videos.init();
    Acordions.init();
    Forms.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (this.category) Header.setCurrentPageCategory(this.category);
    else Header.setCurrentPage();
  }

  show__effect(__call) {
    LogoCanvas.changeColor(this.logo_color);
   
    if (this.isFirstTime) {
      if (isMobile) {
        Preloader.hide(() => {
          Wrap.show(() => { this.afterShow() });
        });
      } else {
        Wrap.show(() => {
          Preloader.hide(() => {
            // TODO: Create an initial animation for this
            LogoCanvas.start(() => { this.afterShow() });
          });
        });
      }
    } else {
      if (isMobile) {
        Wrap.show(() => { this.afterShow() });
      } else {
        LogoCanvas.start(() => {
          Wrap.show(() => { this.afterShow() });
        });
      }
    }
  }

  afterShow() {
    super.afterShow();
    Header.show();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    LogoCanvas.goBlack();
    Keyboard.unmountPage(this.id);
    super.beforeHide();

    if (!isMobile) LogoCanvas.stop();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
    });

    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
