import { gsap } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Basics, isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

class ScrollItem__Services extends VScroll_Item {
    _buttons;
    _services;
    _texts;
    _images;
    _sectionBtns;
    _calls = {
        hoverIn: (e) => this.hoverIn(e.target),
        hoverOut: (e) => this.hoverOut(e.target),
        click: (e) => this.click(e.target),
        clickSection: (e) => this.clickSection(e.target),
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._buttons = GetBy.selector('[data-service]', __link);
        this._services = GetBy.class('__service', __link);
        this._texts = GetBy.class('__text', __link);
        this._images = GetBy.class('__image', __link);
        this._sectionBtns = GetBy.class('__sectionBtn', __link);

        this.addListeners();

        this.onShow = () => { };
        this.onHide = () => { };
        this.onMove = () => { };
    }

    addListeners() {
        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            btn.addEventListener(Basics.clickEvent, this._calls.click);
        }

        for (let i = 0; i < this._sectionBtns.length; i++) {
            const btn = this._sectionBtns[i];
            btn.addEventListener(Basics.clickEvent, this._calls.clickSection);
        }

        if (isTouch) return;

        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            btn.addEventListener(Basics.mouseOver, this._calls.hoverIn);
            btn.addEventListener(Basics.mouseOut, this._calls.hoverOut);
        }
    }

    removeListeners() {
        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            btn.removeEventListener(Basics.clickEvent, this._calls.click);
        }

        for (let i = 0; i < this._sectionBtns.length; i++) {
            const btn = this._sectionBtns[i];
            btn.removeEventListener(Basics.clickEvent, this._calls.clickSection);
        }

        if (isTouch) return;

        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            btn.removeEventListener(Basics.mouseOver, this._calls.hoverIn);
            btn.removeEventListener(Basics.mouseOut, this._calls.hoverOut);
        }
    }

    click(target) {
        this.clearSections();

        for (let i = 0; i < this._services.length; i++) {
            const service = this._services[i];
            if (service.dataset.name === target.dataset.name) service.classList.add('--active');
            else service.classList.remove('--active');
        }

        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            if (btn.dataset.name === target.dataset.name) btn.classList.add('--active');
            else btn.classList.remove('--active');
        }

        this.setSize();
        this.selectFirstSubsection();
    }

    clickSection(target) {
        this.clearSections();

        let delay = isMobile ? 0 : .1;
        for (let i = 0; i < this._texts.length; i++) {
            const text = this._texts[i];
            if (text.dataset.section === target.dataset.section) {
                gsap.delayedCall(delay, () => {
                    text.classList.add('--active');
                });
                if (!isMobile) delay += .1;
            }
        }

        for (let i = 0; i < this._images.length; i++) {
            const image = this._images[i];
            if (image.dataset.section === target.dataset.section) {
                gsap.delayedCall(delay, () => {
                    image.classList.add('--active');
                });
                if (!isMobile) delay += .1;
            }
        }

        for (let i = 0; i < this._sectionBtns.length; i++) {
            const btn = this._sectionBtns[i];
            if (btn.dataset.section === target.dataset.section) btn.classList.add('--active');
        }
    }

    clearSections() {
        // Reset
        for (let i = 0; i < this._texts.length; i++) {
            this._texts[i].classList.remove('--active');
        }
        for (let i = 0; i < this._images.length; i++) {
            this._images[i].classList.remove('--active');
        }
        for (let i = 0; i < this._sectionBtns.length; i++) {
            this._sectionBtns[i].classList.remove('--active');
        }
    }

    setSize() {

    }

    selectFirstSubsection() {
        // Select
        let currentService;
        for (let i = 0; i < this._services.length; i++) {
            const service = this._services[i];
            if (service.classList.contains('--active')) currentService = service;
        }

        const sectionBtn = GetBy.class('__sectionBtn', currentService)[0];
        sectionBtn.classList.add('--active');

        let delay = isMobile ? 0 : .1;
        for (let i = 0; i < this._texts.length; i++) {
            const text = this._texts[i];
            if (text.dataset.section === sectionBtn.dataset.section) {
                gsap.delayedCall(delay, () => {
                    text.classList.add('--active');
                });
                if (!isMobile) delay += .1;
            }
        }

        for (let i = 0; i < this._images.length; i++) {
            const image = this._images[i];
            if (image.dataset.section === sectionBtn.dataset.section) {
                gsap.delayedCall(delay, () => {
                    image.classList.add('--active');
                });
                if (!isMobile) delay += .1;
            }
        }
    }

    hoverIn(target) {
        for (let i = 0; i < this._buttons.length; i++) {
            const btn = this._buttons[i];
            if (btn.dataset.name === target.dataset.name) btn.classList.add('--hover');
            else btn.classList.remove('--hover');
        }
    }

    hoverOut() {
        for (let i = 0; i < this._buttons.length; i++) {
            this._buttons[i].classList.remove('--hover');
        }
    }

    dispose() {
        this.removeListeners();
        super.dispose();
    }
}

Scroll._registerClass('block-services', ScrollItem__Services);
