import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    _container;

    static init() {
        this._container = GetBy.id('Preloader');

        if (isDebug) {
            this._container.style.display = "none";
        }
    }

    static update(__progress) { }

    static hide(__call) {
        if (isDebug) {
            this._container.style.display = "none";
        }

        gsap.to(this._container, {
            duration: .2,
            opacity: 0,
            delay: .3,
            onComplete: () => {
                this._container.style.display = 'none';
                if (__call) __call();
            }
        });
    }
}
