import { ControllerPage } from "../pages/ControllerPage";
import { ControllerWindow } from "../windows/ControllerWindow";
import { GetBy } from "./Element";

export const KEYS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  ESC: 27,
  HOME: 36,
  END: 35,
  AVPAG: 34,
  REPAG: 33
};

export default class Keyboard {
  static isEnabled = false;
  static calls = {};

  static enable() {
    if (!this.isEnabled) {
      this.isEnabled = true;
      document.addEventListener('keydown', (e) => this._check(e));
    }
  };

  static disable() {
    if (this.isEnabled) {
      this.isEnabled = false;
      document.removeEventListener('keydown', (e) => this._check(e));
    }
  };

  static add(key, id, call) {
    if (!this.calls[key]) this.calls[key] = [];
    this.calls[key][id] = call;
  };

  static remove(key, id) {
    if (!this.calls[key]) return;
    this.calls[key] = this.calls[key].filter(kid => kid !== id);
  };

  static mountPage(id) {
    const links = GetBy.selector('a[data-keyboard]');

    for (let index = 0; index < links.length; index++) {
      const link = links[index];
      const key = link.dataset.keyboard;
      Keyboard.add(KEYS[key], `${id}-${index}`, () => { ControllerPage.changePage(link.href) });
    }
  }

  static unmountPage(id) {
    const links = GetBy.selector('a[data-keyboard]');

    for (let index = 0; index < links.length; index++) {
      const link = links[index];
      const key = link.dataset.keyboard;
      Keyboard.remove(KEYS[key], `${id}-${index}`);
    }
  }

  static _check(e) {
    
    Object.keys(this.calls)
      .filter(key => parseInt(key) === e.keyCode)
      .map(key => {
        const k =  this.calls[key];
        Object.keys(k).map(id => { k[id].call(); });
      });
  }

  static _checkManual(key) {
    
    const k =  this.calls[key];
    if(k) Object.keys(k).map(id => { k[id].call(); });
  }
};
