import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Contact extends Default {
  constructor() {
    super();
    document.body.classList.add('--contact');
  }

  //HIDE
  beforeHide() {
    document.body.classList.remove('--contact');
  }
}

ControllerPage._addPage('contact', Contact);
