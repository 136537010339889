import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__WidgetTeam extends VScroll_Item {
    _call;
    _slider;
    _thumbs = [];

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (!isMobile) {
            this._slider = new SliderScroll(__link, {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            });

            this._thumbs = GetBy.class('__thumb', __link);

            this._click = (e) => this.click(e);
            for (let i = 0; i < this._thumbs.length; i++) {
                const thumb = this._thumbs[i];
                thumb.addEventListener(Basics.clickEvent, this._click);
            }
        }

        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    click(e) {
        const p = (e.target.dataset.index / this._thumbs.length).toFixed(3);
        this._slider.goto_percetage(p);
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) {
            this._slider.loop();

            let current = this._slider.progress.toFixed(3) * (this._thumbs.length - 1);
            if (current < .1) current = 0;
            else current = Math.ceil(current);

            for (let i = 0; i < this._thumbs.length; i++) {
                const thumb = this._thumbs[i];

                if (current === i) thumb.classList.add('--current');
                else thumb.classList.remove('--current');
            }
        }
    }

    dispose() {
        for (let i = 0; i < this._thumbs.length; i++) {
            const thumb = this._thumbs[i];
            thumb.removeEventListener(Basics.clickEvent, this._click)
        }

        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
}

Scroll._registerClass('WidgetTeam', ScrollItem__WidgetTeam);
