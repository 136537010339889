import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Page from '../_app/cuchillo/pages/Page';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Cmp from '../windows/Cmp';
import { GetBy } from '../_app/cuchillo/core/Element';
import LogoCanvas from '../components/LogoCanvas';
import { Functions } from '../_app/cuchillo/utils/Functions';
import Header from '../layout/Header';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Home extends Page {
    _code;
    _title;
    _link;
    _currentTitle;
    _visor;
    _visorContainer;
    _thumbnails;
    _thumbContainer;
    _slider;
    _images;
    timeLine;
    _logo;
    _logoWidth = 0;
    logo_color;
    _paddingLogo = Metrics.parseSize('50fpx');
    _calls = {
        click: (e) => this.clickThumbnail(e.target)
    }
    _isLoopEnabled = false;
    _timeToShow = 0;

    constructor() {
        super();

        this._title = GetBy.class('__title', this.container)[0];
        this._link = GetBy.class('__link', this.container)[0];
        this._code = GetBy.class('__code', this.container)[0];
        this._currentTitle = this._title.textContent;
        this._thumbContainer = GetBy.class('__thumbnails', this.container)[0];
        this._thumbnails = GetBy.selector('img', this._thumbContainer);
        this._visor = [...GetBy.selector('.__visor img', this.container)];
        this._visorContainer = GetBy.class('__visorContainer', this.container)[0];
        this._logo = GetBy.class('__logo', this.container)[0];
        this._images = GetBy.class('__image', this.container);

        this.logo_color = this.container.getAttribute("data-logo-color");

        if (isMobile) {
            this.setupItemsMobile();
        } else {
            this.setupItems();
        }

        this.loop();

        document.body.classList.add('--home');
    }

    setupItems() {
        let sizes = Functions.arrayRandom(['--1', '--2', '--3', '--4']);
        let positions = Functions.arrayRandom(['--pos-1', '--pos-2', '--pos-3', '--pos-4']);
        const speeds = Functions.arrayRandom(['.2', '.1', '0']);
        let cont = 0;
        let last = '';

        for (let i = 0; i < 10; i++) {
            const a = GetBy.selector('div', this._images[i])[0];
            gsap.set(a, { opacity: 0 });
        }

        for (let i = 0, j = this._images.length; i < j; i++, cont++) {
            const item = this._images[i];
            const child = item.querySelector('div');
            const image = child.querySelector('img');

            if (cont === 4) {
                cont = 0;
                positions = Functions.arrayRandom(positions);
                sizes = Functions.arrayRandom(sizes);
            }

            if (last === positions[cont]) cont++;

            item.classList.add(positions[cont]);
            item.classList.add(sizes[cont]);
            last = positions[cont];

            image.setAttribute('data-scroll-displace', 'true');
            child.setAttribute('data-scroll-insider', 'true');
            child.setAttribute('data-speed', Functions.arrayRandom(speeds)[0]);
        }

        this.addThumbnailListeners();
    }

    setupItemsMobile() {
        let sizes = Functions.arrayRandom(['--1', '--2', '--3', '--4']);
        let positions = Functions.arrayRandom(['--pos-1', '--pos-2', '--pos-3']);
        const speeds = Functions.arrayRandom(['.1', '.15', '0']);
        let cont = 0;
        let last = '';

        for (let i = 0; i < 10; i++) {
            const a = GetBy.selector('div', this._images[i])[0];
            gsap.set(a, { opacity: 0 });
        }

        for (let i = 0, j = this._images.length; i < j; i++, cont++) {
            const item = this._images[i];
            const child = item.querySelector('div');

            if (cont === 3) {
                cont = 0;
                positions = Functions.arrayRandom(positions);
                sizes = Functions.arrayRandom(sizes);
            }

            if (last === positions[cont]) cont++;

            item.classList.add(positions[cont]);
            item.classList.add(sizes[cont]);
            last = positions[cont];

            child.setAttribute('data-scroll-insider', 'true');
            child.setAttribute('data-speed', Functions.arrayRandom(speeds)[0]);
        }

        this.addThumbnailListeners();
    }

    // SHOW
    beforeShow() {
        super.beforeShow();

        Header.isFixed = true;

        const axis = isMobile ? Scroll.AXIS_X : Scroll.AXIS_Y;
        const multi = isMobile ? 2 : 1;
        Scroll.init(axis, { smooth: true, multiplicator: multi, selector: '[scroll-item-project]' });
        Keyboard.mountPage(this.id);

        Header.setCurrentPage();

        Scroll.engine._items.map(item => {
            item.onShow = () => {
                const strTitle = item.item.getAttribute('data-title');
                if (strTitle !== this._currentTitle.trim()) {
                    this._currentTitle = strTitle;
                    this._code.textContent = item.item.getAttribute('data-code');
                    this._link.href = item.item.getAttribute('data-link');
                    this._title.textContent = this._currentTitle;

                    this.setNewProject(item.item.dataset.project);
                }
            }
            item.onHide = () => { }
        });
    }

    show__effect(__call) {
        LogoCanvas.changeColor(this.logo_color);

        if (this.isFirstTime) {
            this._timeToShow = 1000;

            if (isMobile) {
                Preloader.hide(() => {
                    Wrap.show(() => { this.afterShow() });
                });
            } else {
                Wrap.show(() => {
                    Preloader.hide(() => {
                        LogoCanvas.start(() => { this.afterShow() });
                    });
                });
            }
        } else {
            if (isMobile) {
                Wrap.show(() => { this.afterShow() });
            } else {
                LogoCanvas.start(() => {
                    Wrap.show(() => { this.afterShow() });
                });
            }
        }
    }

    afterShow() {
        super.afterShow();

        LogoCanvas.canvas.classList.add('--behind');
        Header.show();

        Scroll.show();
        Scroll.start();

        let delay = (this._thumbnails.length + 1) * .05;
        for (let i = 0; i < 10; i++) {
            const a = GetBy.selector('div', this._images[i])[0];
            gsap.set(a, { opacity: 1, delay });
            delay += .05;
        }

        setTimeout(()=> {
            Scroll.goto(Metrics.HEIGHT);
        }, this._timeToShow);
        
        this._isLoopEnabled = true;
    }

    setNewProject(index) {
        console.log('SET NEW PROJECT');

        let str = '';
        const images = GetBy.selector(`.__image[data-project="${index}"`);

        const first = images[0];
        const img0 = GetBy.selector('img', first)[0];
        str += `<li><img src="${img0.src}" style="opacity: 0;" /></li>`;

        for (let i = 1; i < images.length; i++) {
            const item = images[i];
            const img = GetBy.selector('img', item)[0];
            str += `<li><img src="${img.src}" style="opacity: 0;" /></li>`;
        }

        this.removeThumbnailListeners();
        this._visor.filter(img => img.classList.contains('--show'))[0].setAttribute('src', img0.src);
        this._thumbContainer.innerHTML = str;
        this._thumbnails = GetBy.selector('img', this._thumbContainer);

        this.setAnimation();
        this.doAnimation();
        this.addThumbnailListeners();
    }

    setAnimation() {
        gsap.set(this._thumbnails, { opacity: 0 });
    }

    doAnimation() {
        let delay = 0;
        for (let i = 0; i < this._thumbnails.length; i++) {
            const thumb = this._thumbnails[i];
            delay += (.08 - .005 * i);
            gsap.set(thumb, { opacity: 1, delay });
        }

        gsap.delayedCall(delay, () => { this._thumbnails[0].parentNode.classList.add('--current') });
        gsap.set(this._visor.filter(img => img.classList.contains('--show'))[0], { opacity: 1, delay });
    }

    addThumbnailListeners() {
        for (let i = 0; i < this._thumbnails.length; i++) {
            const thumb = this._thumbnails[i];
            thumb.addEventListener(Basics.clickEvent, this._calls.click);
        }
    }

    removeThumbnailListeners() {
        for (let i = 0; i < this._thumbnails.length; i++) {
            const thumb = this._thumbnails[i];
            thumb.removeEventListener(Basics.clickEvent, this._calls.click);
        }
    }

    clickThumbnail(target) {
        const next = this._visor.filter(img => !img.classList.contains('--show'))[0];
        const prev = this._visor.filter(img => img.classList.contains('--show'))[0];

        gsap.killTweensOf(prev);

        prev.classList.remove('--show');
        next.classList.add('--show');
        next.setAttribute('src', target.getAttribute('src'));
        next.onload = () => {
            gsap.to(next, {
                opacity: 1, duration: .1, onComplete: () => {
                    prev.style.opacity = '0';
                    // prev.setAttribute('src', '');
                }
            });
        };

        for (let i = 0; i < this._thumbnails.length; i++) {
            const thumb = this._thumbnails[i];
            thumb.parentNode.classList.remove('--current');
        }
        target.parentNode.classList.add('--current');
    }

    //HIDE
    beforeHide() {
        LogoCanvas.goBlack();
        Keyboard.unmountPage(this.id);
        super.beforeHide();
    }

    hide__effect() {
        Scroll.hide();
        Wrap.hide(() => { this.afterHide(); });
    }

    afterHide() {
        Scroll.dispose();

        LogoCanvas.canvas.classList.remove('--behind');
        Header.isFixed = false;
        document.body.classList.remove('--home');

        [...GetBy.selector('[data-toggle-cmp]')].map(item => {
            item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
        });

        super.afterHide();
    }

    loop() {
        if (isMobile) return;

        const offset = Math.min(Math.abs(Scroll.y / Metrics.HEIGHT), 1);
        const y = Maths.lerp(100, 0, offset);
        gsap.set(this._visorContainer, { x: `${y}%` })

        const offset2 = Math.min(Math.abs(Scroll.y / Metrics.HEIGHT), 1);
        const width = Maths.lerp(Metrics.WIDTH, this._logoWidth, offset2);

        gsap.set(this._logo, { width });

        if (this._isLoopEnabled) {
            LogoCanvas.resetWidth();
            LogoCanvas.width = width - this._paddingLogo;
        }
    }

    resize() {
        super.resize();

        this.widthVisor = this._visorContainer.offsetWidth;
        this._paddingLogo = Metrics.parseSize("50fpx");

        if (this._logo) {
            this._logo.style = '';
            this._logoWidth = this._logo.offsetWidth;
        }
    }
}

ControllerPage._addPage('home', Home);